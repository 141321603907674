function getMyWinEnv(name) {
    const configOptions = {
        "VUE_APP_API": process.env.VUE_APP_API,
        "VUE_APP_META_SLUG": process.env.VUE_APP_META_SLUG,
        "VUE_APP_URL": process.env.VUE_APP_URL,
    };

    // const configOptions = {
    //     "VUE_APP_API": "https://api.mywin.app/v1",
    //     "VUE_APP_META_SLUG": "mywin-popup-slug",
    //     "VUE_APP_URL": "https://pop-up.mywin.app",
    // };

    const value = configOptions[name];

    if (!value) {
        throw new Error(`Environment variable ${name} not found`);
    }

    return value;
}


/**
 * Get popup data from api and start application.
 *
 * @returns {void}
 */
async function getContent() {
    if(document.readyState == "loading") {
        console.error(`
            MyWin Popup Error:
            ==================
            Cannot load MyWin while the page is still loading

            Are you missing defer property in your script?
            <script defer src="./boot.js"></script>`
        );
        return;
    }


    const metaTag = await getMyWinSlugMetaTag();

    if (metaTag === null) {
        return;
    }

    const url = getMyWinEnv("VUE_APP_API") + "/popup?slug=" + metaTag.content;
    try {
        const response = await fetch(url);
        if (!response.ok) {
            if (response.status !== 404) {
                throw new Error(`Response status: ${response.status}`);
            }

            throw new Error(
                `
                MyWin Popup Error:
                ==================
                The defined slug doesn't exist please update meta tag.

                Reference: \n` + metaTag.outerHTML
            );
        }

        const vueElement = await createVueLocation();
        await loadWrapperCss();

        const json = await response.json();

        if (json.popupShowOn == "delay") {
            if (localStorage.getItem("popupClosed") === "true") {
                return;
            }

            await new Promise(resolve =>
                setTimeout(resolve, json.popupDelay * 1000)
            );
            await loadVueCssApplication(vueElement, json.popupLocation);
        } else if (json.popupShowOn === "buttonClick") {
            popupShowOnButtonClick(vueElement, json.popupLocation);
        } else if (json.popupShowOn === "customIdClick") {
            const customElement = document.getElementById(
                json.popupCustomIdClick
            );

            if (!customElement) {
                console.error(
                    `Mywin: Element with id: ${
                        json.popupCustomIdClick
                    } not found.`
                );
                return;
            }

            customElement.addEventListener("click", async () => {
                if (!document.querySelector("#mywin-popup-styling")) {
                    localStorage.setItem("popupClosed", "true");
                    loadVueCssApplication(vueElement, json.popupLocation);
                } else {
                    await showPopUp();
                    await setCloseEventListener();
                }
            });
        }
    } catch (error) {
        console.error(error.message);
    }
}

/**
 *  Create new div element where the vue application should be loaded.
 *
 * @returns {void}
 */
async function createVueLocation() {
    const div = document.createElement("div");
    div.id = "pop-up-mywin";

    return document.body.appendChild(div);
}

/**
 * Get meta data that is required to load mywin pop-up
 *
 * @returns {?HTMLMetaElement}
 */
async function getMyWinSlugMetaTag() {
    const metaTags = document.getElementsByTagName("meta");
    const slug = getMyWinEnv("VUE_APP_META_SLUG");

    for (let i = 0; i < metaTags.length; i++) {
        if (metaTags[i].name === slug) {
            return metaTags[i];
        }
    }

    console.error(`
        MyWin Popup Error:
        ==================
        Missing slug meta tag for loading pop-up.
        Please ensure that a meta tag with the appropriate slug is included in the HTML head section.

        Example:
        <meta name="${slug}" content="{{your-slug-here}}">
    `);

    return null;
}

/**
 * Load css for pop-up.
 *
 * @param {?HTMLElement} vueElement
 * @param {string}} popupLocation
 * @returns {void}
 */
async function loadVueCssApplication(vueElement, popupLocation) {
    let link = document.createElement("link");
    link.rel = "stylesheet";
    link.id = "mywin-popup-styling";
    link.href = getMyWinEnv("VUE_APP_URL") + "/app.bundle.css";
    const app = document.getElementById("mywin-app");
    if (app) {
        app.style.display = "block";
    }

    link.onload = async () => {
        loadVueJsApplication(vueElement, popupLocation);
    };

    link.onerror = () => {
        console.error("Failed to load the Vue css app script.");
    };

    document.head.appendChild(link);
}

/**
 * Load styling for the wrapper.
 */
async function loadWrapperCss() {
    let link = document.createElement("link");
    link.rel = "stylesheet";
    link.id = "mywin-wrapper-styling";
    link.href = getMyWinEnv("VUE_APP_URL") + "/wrapper.css";

    document.head.appendChild(link);
}

/**
 * Load vue application
 *
 * @param {?HTMLElement} vueElement
 * @param {string} [popupLocation]
 */
async function loadVueJsApplication(vueElement, popupLocation) {
    let script = document.createElement("script");
    script.src = getMyWinEnv("VUE_APP_URL") + "/app.bundle.js";
    script.id = "mywin-popup-script";
    script.defer = true;

    await vueElement.parentNode.insertBefore(script, vueElement.nextSibling);

    script.onload = async () => {
        await setPopupLocation(popupLocation);
        await showPopUp();
        await setCloseEventListener();
    };

    script.onerror = () => {
        console.error("Failed to load the Vue js app script.");
    };
}

/**
 * Show popup
 *
 * @returns {void}
 */
async function showPopUp() {
    const appElement = await getApp();
    appElement.style.display = "block";
}

/**
 * Get parent element inside the vue application.
 *
 * @returns {?HTMLElement}
 */
async function getApp() {
    return document.getElementById("mywin-app");
}

/**
 * Set location where the pop-up needs to be displayed.
 *
 * @param {String} location
 */
async function setPopupLocation(location) {
    (await getApp()).classList.add(location);
}

/**
 * Show popup on button click
 * @param {?HTMLElement} vueElement
 * @param {string} [popupLocation]
 * @returns {void}
 */
function popupShowOnButtonClick(vueElement, popupLocation) {
    if (localStorage.getItem("popupClosed") === "true") {
        return;
    }

    const button = document.createElement("button");
    button.innerText = "Draai nu!";
    button.style.cssText = `
        background-color: #000;
        color: #fff;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        border-radius: 5px;
        cursor: pointer;
        width: 100%;
        max-width: 300px;
        display: block;
    `;

    const popup = document.createElement("div");
    popup.style.cssText = `
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: white;
        padding: 20px;
        border-radius: 10px 10px 0 0;
        border: 1px;
        border-color: #c1beba;
        box-shadow: 0px 4px 20px 0px #0000003D;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        z-index: 1000;
    `;

    const title = document.createElement("h2");
    title.innerText = "Draai en Win!";
    title.style.cssText = `
        margin-top: 0;
        font-size: 20px;
        font-weight: bold;
    `;

    const description = document.createElement("p");
    description.innerText =
        "Speel mee door aan het rad te draaien en maak kans op geweldige prijzen!";
    description.style.cssText = `
        margin-bottom: 15px;
        font-size: 14px;
    `;

    const closeButton = document.createElement("span");
    closeButton.innerHTML = "&times;";
    closeButton.style.cssText = `
        position: absolute;
        top: 10px;
        right: 15px;
        font-size: 24px;
        cursor: pointer;
    `;

    closeButton.addEventListener("click", () => {
        popup.remove();
        localStorage.setItem("popupClosed", "true");
    });

    popup.appendChild(closeButton);
    popup.appendChild(title);
    popup.appendChild(description);
    popup.appendChild(button);

    button.addEventListener("click", async () => {
        await loadVueCssApplication(vueElement, popupLocation);
        popup.remove();
    });

    function adjustPopupSize() {
        const viewportWidth = window.innerWidth;
        if (viewportWidth <= 600) {
            popup.style.width = "100%";
            popup.style.left = "0";
            popup.style.right = "0";
            popup.style.bottom = "0";
            popup.style.borderRadius = "8px 8px 0 0";
        } else {
            popup.style.width = "300px";
            popup.style.left = "20px";
            popup.style.right = "auto";
            popup.style.bottom = "20px";
            popup.style.borderRadius = "8px";
        }
    }

    adjustPopupSize();
    window.addEventListener("resize", adjustPopupSize);

    document.body.appendChild(popup);
}

/**
 * This method will watch the close element in the vue application.
 * When click event fired it will remove the application from the dom.
 *
 * @returns {void}
 */
async function setCloseEventListener() {
    const closeButton = await elementIsDefined("mywin-close-popup-button");

    closeButton.addEventListener("click", async () => {
        popupShowOnButtonClick();
        const app = getApp();
        app.style.display = "none";
    });
}

/**
 * This method will watch mutations in the DOM.
 *
 * When it found element it will return it.
 *
 * @param {int} id
 * @returns {Promise<HTMLElement>}
 */
async function elementIsDefined(id) {
    return new Promise(resolve => {
        const targetNode = document.body;

        const callback = function(mutationsList, observer) {
            for (let mutation of mutationsList) {
                if (mutation.type === "childList") {
                    const appElement = document.getElementById(id);
                    if (appElement !== null) {
                        observer.disconnect();
                        resolve(appElement);
                        break;
                    }
                }
            }
        };

        const observer = new MutationObserver(callback);

        observer.observe(targetNode, { childList: true, subtree: true });
    });
}

getContent();